export const state = () => ({
  path: 'index',
  name: "",
})
export const mutations = {
  setPage(state, payload) {
    // state.path = payload.path
    state.name = payload.name
  },
}
export const getters = {
}