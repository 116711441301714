export let form_config = {
  headers: {
    "content-type": "multipart/form-data",
  }
}  

export let item = {
  category_id: null,
  only_admin: false,
  price: 0,
  discount_price: 0,
  tax_rate: 10,
  min_order_size: 1,
  has_stock_limit: false,
  stock_limit_size: 0,
  auto_reset_stock_limit: true,
  option_ids: [],
  image: null,
  name: "",
  name_en: "",
  name_zh: "",
  description: "",
  description_en: "",
  description_zh: "",
}

export let option = {
  name: "",
  name_en: "",
  name_zh: "",
  option_type: "radio",
  max_count: 1,
  is_required: true,
  settings: [{name: null, price: 0, ja: null, en: null, zh: null}]
}

export let category = {
  name: "",
  name_en: "",
  name_zh: "",
}

export let viking = {
  category_id: null,
  name: "",
  name_en: "",
  name_zh: "",
  description: "",
  description_en: "",
  description_zh: "",
  image: null,
  price: 0,
  discount_price: 0,
  is_include_tax: true,
  tax_rate: 10,
  show_onfloor: true,
  show_online: true,
  disable_related_items: false,
  min_customer_size: 1,
  max_order_size: 10,
  order_limit_time: 120,
  last_order_time: 15,
  has_stock_limit: false,
  stock_limit_size: 0,
  auto_reset_stock_limit: false,
  stock_size: 0,
  item_ids: [],
}

export let freebox = {
  category_id: null,
  sort: 0,
  name: "",
  name_en: "",
  name_zh: "",
  show_name: true,
  show_on_category: true,
  show_onfloor: true,
  show_online: true,
  item_ids: [],
}

export let payment_method = {
  name: "",
}

export let staff = {
  name: "",
  name_inner: "",
}

export let table = {
  name: "",
}

///// options
export let yearOptions = [
	{ text: '2022', value: 2022 },
	{ text: '2023', value: 2023 },
	{ text: '2024', value: 2024 },
	{ text: '2025', value: 2025 },
	{ text: '2026', value: 2026 },
	{ text: '2027', value: 2027 },
	{ text: '2028', value: 2028 },
	{ text: '2029', value: 2029 },
	{ text: '2030', value: 2030 },
]
export let taxOptions = [
  { text: '税込み', value: true },
  { text: '税抜', value: false },
]
export let taxRateOptions = [
  { text: '10%(標準)', value: 10 },
  { text: '8%(軽減)', value: 8 },
  { text: '非課税', value: 0 },
]
export let taxSettingOptions = [
  { text: '税込表示', value: true },
  { text: '税抜表示', value: false },
]
export let otoshiTaxOptions = [
  { text: '税込表示', value: true },
  { text: '税抜表示', value: false },
]
export let changeHourOptions = [
  { text: '00:00', value: 0 },
  { text: '01:00', value: 1 },
  { text: '02:00', value: 2 },
  { text: '03:00', value: 3 },
  { text: '04:00', value: 4 },
  { text: '05:00', value: 5 },
  { text: '06:00', value: 6 },
  { text: '07:00', value: 7 },
  { text: '08:00', value: 8 },
  { text: '09:00', value: 9 },
  { text: '10:00', value: 10 },
  { text: '11:00', value: 11 },
  { text: '12:00', value: 12 },
  { text: '13:00', value: 13 },
  { text: '14:00', value: 14 },
  { text: '15:00', value: 15 },
  { text: '16:00', value: 16 },
  { text: '17:00', value: 17 },
  { text: '18:00', value: 18 },
  { text: '19:00', value: 19 },
  { text: '20:00', value: 20 },
  { text: '21:00', value: 21 },
  { text: '22:00', value: 22 },
  { text: '23:00', value: 23 },
]
export let gpdLevelOptions = [
  { text: '普通(100m)', value: "normal" },
  { text: '高い(推奨)(50m)', value: "high" },
  { text: '非常に高い(10m)', value: "xhigh" },
]
export let customerSessionOptions = [
  { text: '5分', value: 5 },
  { text: '10分', value: 10 },
  { text: '20分', value: 20 },
  { text: '30分', value: 30 },
  { text: '40分', value: 40 },
  { text: '50分', value: 50 },
  { text: '60分', value: 60 },
  { text: '70分', value: 70 },
  { text: '80分', value: 80 },
  { text: '90分(推奨)', value: 90 },
  { text: '120分', value: 120 },
  { text: '150分', value: 150 },
  { text: '180分', value: 180 },
  { text: '360分', value: 360 },
]
export let plans = [
   {name: "ベーシック", type: "basic", price: 0, can_handy: false, disable_logo: false, can_line: false, handy_login_limit: 0, data_limit: 30},
   {name: "アドバンス", type: "advance", price: 8800, can_handy: true, disable_logo: true, can_line: true, handy_login_limit: 3, data_limit: 365},
   {name: "プレミアム", type: "premium", price: 13200, can_handy: true, disable_logo: true, can_line: true, handy_login_limit: 8, data_limit: 730},
]
export let recipet_sample = {
  "request_type":null,
  "request_uid":null,
  "timestamp":null,
  "items":[
     {
        "type":"text_align",
        "align":"center"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text_size",
        "width":1,
        "height":2
     },
     {
        "type":"text",
        "text":"チュートリアル店舗\n"
     },
     {
        "type":"feed",
        "unit":20
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"〒160-0004東京都新宿区四谷2-12-5四谷ISYビル4F\n"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text",
        "text":"Tel.03-5360-7066\n"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text",
        "text":"ご来店誠にありがとうございます。\n"
     },
     {
        "type":"feed",
        "unit":20
     },
     {
        "type":"text_align",
        "align":"right"
     },
     {
        "type":"text",
        "text":"テーブル:T2\n"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"------------------------------------------------"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text_align",
        "align":"left"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"焼とり丼                          1         \\350\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"とり白湯めん 【リニューアル】     1         \\350\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"黒霧島(芋）／90ml 純アルコール量：18.00g\n                                  1         \\350\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"よかいち（麦）／90ml 純アルコール量：18.00g\n                                  1         \\350\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"ザ・プレミアム・モルツ 純アルコール量：13.34g\n                                  1         \\350\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"ザ・プレミアム・モルツ 純アルコール量：13.34g\n                                  1         \\350\n"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"------------------------------------------------"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text_align",
        "align":"left"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"割引・割増                                    \\0\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"小計                                      \\2,100\n"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"------------------------------------------------"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"内：標準税率対象メニュー                  \\1,910\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"内：軽減税率対象メニュー                      \\0\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"内：標準税                                  \\190\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"内：軽減税                                    \\0\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"内：消費税等合計                            \\190\n"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"text",
        "text":"------------------------------------------------"
     },
     {
        "type":"feed",
        "unit":10
     },
     {
        "type":"text_size",
        "width":2,
        "height":1
     },
     {
        "type":"text",
        "text":"合計              \\2,100\n"
     },
     {
        "type":"text_size",
        "width":1,
        "height":1
     },
     {
        "type":"feed",
        "unit":20
     },
     {
        "type":"cut",
        "cut_type":"feed"
     },
     {
        "type":"drawer"
     }
  ]
}

