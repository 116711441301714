export default function({ store, redirect, route }) {
  if(!store.getters['auth/isLogin']){
    console.log("in logout", route)
    store.commit("devise/setLoginRedirectPath", route.fullPath)
    setTimeout(function() {
      window.storeCtl.commit("alert/setError", "ログインしてください。")
    }, 500);
    return redirect('/login/')
  }
}
