import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import { form_config } from '~/utils/models'
import { plans } from '~/utils/models'

const getDefaultState = () => {
  return {
    // shop
    settings: {},
    shopTrend: {},

    // staff
    currentStaff: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },

  setSettings(state, settings) {
    // FIXME: 何故か本番でRailsのAPIがJSON型をStrigで返すので対策
    if(typeof(settings.rights) === "string") settings.rights = JSON.parse(settings.rights)
    state.settings = settings
  },
  setShopTrend(state, shopTrend) {
    state.shopTrend = shopTrend
  },

  setStaff(state, staff){
    state.currentStaff = staff
  },
}
export const getters = {
  currentPlan(state) {
    return _.find(plans, ["type", state.settings.plan_type])
  },
}
export const actions = {
  async getSettings(context){
    await httpClient
    .get('/shp/account.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setSettings", res.data.data.shop)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  async changeOpenStatus(context){
    let newStatus = null
    if(context.state.settings.business_status === "open") newStatus = "point_closed"
    if(context.state.settings.business_status === "point_closed") newStatus = "closed"
    if(context.state.settings.business_status === "closed") newStatus = "open"
    const formData = new FormData();
    formData.append(`shop[business_status]`, newStatus)
    await window.storeCtl.dispatch('shop/update', formData)
  },

  async update(context, formData){
    let self = this
    window.storeCtl.commit("loading/start")
    await httpClient
      .put(`/shp/account.json`, formData, form_config)
      .then(async (res) => {
        window.storeCtl.commit("loading/stop")
        if (res.data.status === 'success') {
          context.commit("setSettings", res.data.data.shop)
          window.storeCtl.commit("alert/setSuccess", "更新しました")
          return true
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
          return false
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
  },

  async getShopTrend(context){
    await httpClient
    .get('/shp/sales/trend.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setShopTrend", res.data.data.shop_trend)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
  })
  },

}
