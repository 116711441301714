import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61562f91 = () => interopDefault(import('../pages/accounting/index.vue' /* webpackChunkName: "pages/accounting/index" */))
const _191437f3 = () => interopDefault(import('../pages/guides/index.vue' /* webpackChunkName: "pages/guides/index" */))
const _290dcafa = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _1a1210aa = () => interopDefault(import('../pages/masters/index.vue' /* webpackChunkName: "pages/masters/index" */))
const _01446461 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _2c8a9c0e = () => interopDefault(import('../pages/sales_data/index.vue' /* webpackChunkName: "pages/sales_data/index" */))
const _ae55b842 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _6140403e = () => interopDefault(import('../pages/tables/index.vue' /* webpackChunkName: "pages/tables/index" */))
const _3b57b4a5 = () => interopDefault(import('../pages/accounting/calculate.vue' /* webpackChunkName: "pages/accounting/calculate" */))
const _66c0af92 = () => interopDefault(import('../pages/accounting/receipt.vue' /* webpackChunkName: "pages/accounting/receipt" */))
const _9c1c992c = () => interopDefault(import('../pages/login/deputy.vue' /* webpackChunkName: "pages/login/deputy" */))
const _201cf39b = () => interopDefault(import('../pages/login/handy.vue' /* webpackChunkName: "pages/login/handy" */))
const _67deaf98 = () => interopDefault(import('../pages/login/re_password.vue' /* webpackChunkName: "pages/login/re_password" */))
const _bfa503ee = () => interopDefault(import('../pages/login/singup.vue' /* webpackChunkName: "pages/login/singup" */))
const _579e4256 = () => interopDefault(import('../pages/masters/categories/index.vue' /* webpackChunkName: "pages/masters/categories/index" */))
const _1ecf53a9 = () => interopDefault(import('../pages/masters/freeboxes/index.vue' /* webpackChunkName: "pages/masters/freeboxes/index" */))
const _6c29770c = () => interopDefault(import('../pages/masters/options/index.vue' /* webpackChunkName: "pages/masters/options/index" */))
const _f30405f6 = () => interopDefault(import('../pages/masters/vikings/index.vue' /* webpackChunkName: "pages/masters/vikings/index" */))
const _cd5d2f7a = () => interopDefault(import('../pages/orders/done.vue' /* webpackChunkName: "pages/orders/done" */))
const _5c70cf7e = () => interopDefault(import('../pages/orders/histories.vue' /* webpackChunkName: "pages/orders/histories" */))
const _ed304022 = () => interopDefault(import('../pages/orders/new.vue' /* webpackChunkName: "pages/orders/new" */))
const _302b2e9d = () => interopDefault(import('../pages/sales_data/export.vue' /* webpackChunkName: "pages/sales_data/export" */))
const _745fef1e = () => interopDefault(import('../pages/sales_data/per_item.vue' /* webpackChunkName: "pages/sales_data/per_item" */))
const _43ef4258 = () => interopDefault(import('../pages/settings/accounting.vue' /* webpackChunkName: "pages/settings/accounting" */))
const _cce76c3e = () => interopDefault(import('../pages/settings/call.vue' /* webpackChunkName: "pages/settings/call" */))
const _b94ebcda = () => interopDefault(import('../pages/settings/card.vue' /* webpackChunkName: "pages/settings/card" */))
const _5a6ad50f = () => interopDefault(import('../pages/settings/charges/index.vue' /* webpackChunkName: "pages/settings/charges/index" */))
const _29ae84c0 = () => interopDefault(import('../pages/settings/edit_passcode.vue' /* webpackChunkName: "pages/settings/edit_passcode" */))
const _0353878d = () => interopDefault(import('../pages/settings/edit.vue' /* webpackChunkName: "pages/settings/edit" */))
const _5759d1a9 = () => interopDefault(import('../pages/settings/email.vue' /* webpackChunkName: "pages/settings/email" */))
const _725a32d5 = () => interopDefault(import('../pages/settings/handies.vue' /* webpackChunkName: "pages/settings/handies" */))
const _2aa07712 = () => interopDefault(import('../pages/settings/line.vue' /* webpackChunkName: "pages/settings/line" */))
const _7cc0374a = () => interopDefault(import('../pages/settings/order.vue' /* webpackChunkName: "pages/settings/order" */))
const _16eff381 = () => interopDefault(import('../pages/settings/passcode.vue' /* webpackChunkName: "pages/settings/passcode" */))
const _4c5d361e = () => interopDefault(import('../pages/settings/password.vue' /* webpackChunkName: "pages/settings/password" */))
const _667d19c9 = () => interopDefault(import('../pages/settings/payment_methods/index.vue' /* webpackChunkName: "pages/settings/payment_methods/index" */))
const _7ca75432 = () => interopDefault(import('../pages/settings/printer.vue' /* webpackChunkName: "pages/settings/printer" */))
const _5abd1843 = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _0f0c45d9 = () => interopDefault(import('../pages/settings/staffs/index.vue' /* webpackChunkName: "pages/settings/staffs/index" */))
const _59c30110 = () => interopDefault(import('../pages/settings/tax.vue' /* webpackChunkName: "pages/settings/tax" */))
const _91a78922 = () => interopDefault(import('../pages/tables/new.vue' /* webpackChunkName: "pages/tables/new" */))
const _011ab724 = () => interopDefault(import('../pages/masters/categories/new.vue' /* webpackChunkName: "pages/masters/categories/new" */))
const _dc13fd92 = () => interopDefault(import('../pages/masters/freeboxes/new.vue' /* webpackChunkName: "pages/masters/freeboxes/new" */))
const _50873daa = () => interopDefault(import('../pages/masters/items/new.vue' /* webpackChunkName: "pages/masters/items/new" */))
const _0249f248 = () => interopDefault(import('../pages/masters/options/new.vue' /* webpackChunkName: "pages/masters/options/new" */))
const _b6915cda = () => interopDefault(import('../pages/masters/vikings/new.vue' /* webpackChunkName: "pages/masters/vikings/new" */))
const _3ec26152 = () => interopDefault(import('../pages/settings/payment_methods/new.vue' /* webpackChunkName: "pages/settings/payment_methods/new" */))
const _4029bf67 = () => interopDefault(import('../pages/settings/staffs/new.vue' /* webpackChunkName: "pages/settings/staffs/new" */))
const _4562a83d = () => interopDefault(import('../pages/masters/categories/_uid.vue' /* webpackChunkName: "pages/masters/categories/_uid" */))
const _601ebeec = () => interopDefault(import('../pages/masters/freeboxes/_uid.vue' /* webpackChunkName: "pages/masters/freeboxes/_uid" */))
const _38f21b12 = () => interopDefault(import('../pages/masters/items/_uid.vue' /* webpackChunkName: "pages/masters/items/_uid" */))
const _6a1ad199 = () => interopDefault(import('../pages/masters/options/_uid.vue' /* webpackChunkName: "pages/masters/options/_uid" */))
const _15595bae = () => interopDefault(import('../pages/masters/vikings/_uid.vue' /* webpackChunkName: "pages/masters/vikings/_uid" */))
const _5661966a = () => interopDefault(import('../pages/settings/payment_methods/_uid.vue' /* webpackChunkName: "pages/settings/payment_methods/_uid" */))
const _2f96af4c = () => interopDefault(import('../pages/settings/staffs/_uid.vue' /* webpackChunkName: "pages/settings/staffs/_uid" */))
const _5181ad52 = () => interopDefault(import('../pages/tables/_uid.vue' /* webpackChunkName: "pages/tables/_uid" */))
const _2003f0e9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accounting/",
    component: _61562f91,
    pathToRegexpOptions: {"strict":true},
    name: "accounting"
  }, {
    path: "/guides/",
    component: _191437f3,
    pathToRegexpOptions: {"strict":true},
    name: "guides"
  }, {
    path: "/login/",
    component: _290dcafa,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/masters/",
    component: _1a1210aa,
    pathToRegexpOptions: {"strict":true},
    name: "masters"
  }, {
    path: "/orders/",
    component: _01446461,
    pathToRegexpOptions: {"strict":true},
    name: "orders"
  }, {
    path: "/sales_data/",
    component: _2c8a9c0e,
    pathToRegexpOptions: {"strict":true},
    name: "sales_data"
  }, {
    path: "/settings/",
    component: _ae55b842,
    pathToRegexpOptions: {"strict":true},
    name: "settings"
  }, {
    path: "/tables/",
    component: _6140403e,
    pathToRegexpOptions: {"strict":true},
    name: "tables"
  }, {
    path: "/accounting/calculate/",
    component: _3b57b4a5,
    pathToRegexpOptions: {"strict":true},
    name: "accounting-calculate"
  }, {
    path: "/accounting/receipt/",
    component: _66c0af92,
    pathToRegexpOptions: {"strict":true},
    name: "accounting-receipt"
  }, {
    path: "/login/deputy/",
    component: _9c1c992c,
    pathToRegexpOptions: {"strict":true},
    name: "login-deputy"
  }, {
    path: "/login/handy/",
    component: _201cf39b,
    pathToRegexpOptions: {"strict":true},
    name: "login-handy"
  }, {
    path: "/login/re_password/",
    component: _67deaf98,
    pathToRegexpOptions: {"strict":true},
    name: "login-re_password"
  }, {
    path: "/login/singup/",
    component: _bfa503ee,
    pathToRegexpOptions: {"strict":true},
    name: "login-singup"
  }, {
    path: "/masters/categories/",
    component: _579e4256,
    pathToRegexpOptions: {"strict":true},
    name: "masters-categories"
  }, {
    path: "/masters/freeboxes/",
    component: _1ecf53a9,
    pathToRegexpOptions: {"strict":true},
    name: "masters-freeboxes"
  }, {
    path: "/masters/options/",
    component: _6c29770c,
    pathToRegexpOptions: {"strict":true},
    name: "masters-options"
  }, {
    path: "/masters/vikings/",
    component: _f30405f6,
    pathToRegexpOptions: {"strict":true},
    name: "masters-vikings"
  }, {
    path: "/orders/done/",
    component: _cd5d2f7a,
    pathToRegexpOptions: {"strict":true},
    name: "orders-done"
  }, {
    path: "/orders/histories/",
    component: _5c70cf7e,
    pathToRegexpOptions: {"strict":true},
    name: "orders-histories"
  }, {
    path: "/orders/new/",
    component: _ed304022,
    pathToRegexpOptions: {"strict":true},
    name: "orders-new"
  }, {
    path: "/sales_data/export/",
    component: _302b2e9d,
    pathToRegexpOptions: {"strict":true},
    name: "sales_data-export"
  }, {
    path: "/sales_data/per_item/",
    component: _745fef1e,
    pathToRegexpOptions: {"strict":true},
    name: "sales_data-per_item"
  }, {
    path: "/settings/accounting/",
    component: _43ef4258,
    pathToRegexpOptions: {"strict":true},
    name: "settings-accounting"
  }, {
    path: "/settings/call/",
    component: _cce76c3e,
    pathToRegexpOptions: {"strict":true},
    name: "settings-call"
  }, {
    path: "/settings/card/",
    component: _b94ebcda,
    pathToRegexpOptions: {"strict":true},
    name: "settings-card"
  }, {
    path: "/settings/charges/",
    component: _5a6ad50f,
    pathToRegexpOptions: {"strict":true},
    name: "settings-charges"
  }, {
    path: "/settings/edit_passcode/",
    component: _29ae84c0,
    pathToRegexpOptions: {"strict":true},
    name: "settings-edit_passcode"
  }, {
    path: "/settings/edit/",
    component: _0353878d,
    pathToRegexpOptions: {"strict":true},
    name: "settings-edit"
  }, {
    path: "/settings/email/",
    component: _5759d1a9,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email"
  }, {
    path: "/settings/handies/",
    component: _725a32d5,
    pathToRegexpOptions: {"strict":true},
    name: "settings-handies"
  }, {
    path: "/settings/line/",
    component: _2aa07712,
    pathToRegexpOptions: {"strict":true},
    name: "settings-line"
  }, {
    path: "/settings/order/",
    component: _7cc0374a,
    pathToRegexpOptions: {"strict":true},
    name: "settings-order"
  }, {
    path: "/settings/passcode/",
    component: _16eff381,
    pathToRegexpOptions: {"strict":true},
    name: "settings-passcode"
  }, {
    path: "/settings/password/",
    component: _4c5d361e,
    pathToRegexpOptions: {"strict":true},
    name: "settings-password"
  }, {
    path: "/settings/payment_methods/",
    component: _667d19c9,
    pathToRegexpOptions: {"strict":true},
    name: "settings-payment_methods"
  }, {
    path: "/settings/printer/",
    component: _7ca75432,
    pathToRegexpOptions: {"strict":true},
    name: "settings-printer"
  }, {
    path: "/settings/security/",
    component: _5abd1843,
    pathToRegexpOptions: {"strict":true},
    name: "settings-security"
  }, {
    path: "/settings/staffs/",
    component: _0f0c45d9,
    pathToRegexpOptions: {"strict":true},
    name: "settings-staffs"
  }, {
    path: "/settings/tax/",
    component: _59c30110,
    pathToRegexpOptions: {"strict":true},
    name: "settings-tax"
  }, {
    path: "/tables/new/",
    component: _91a78922,
    pathToRegexpOptions: {"strict":true},
    name: "tables-new"
  }, {
    path: "/masters/categories/new/",
    component: _011ab724,
    pathToRegexpOptions: {"strict":true},
    name: "masters-categories-new"
  }, {
    path: "/masters/freeboxes/new/",
    component: _dc13fd92,
    pathToRegexpOptions: {"strict":true},
    name: "masters-freeboxes-new"
  }, {
    path: "/masters/items/new/",
    component: _50873daa,
    pathToRegexpOptions: {"strict":true},
    name: "masters-items-new"
  }, {
    path: "/masters/options/new/",
    component: _0249f248,
    pathToRegexpOptions: {"strict":true},
    name: "masters-options-new"
  }, {
    path: "/masters/vikings/new/",
    component: _b6915cda,
    pathToRegexpOptions: {"strict":true},
    name: "masters-vikings-new"
  }, {
    path: "/settings/payment_methods/new/",
    component: _3ec26152,
    pathToRegexpOptions: {"strict":true},
    name: "settings-payment_methods-new"
  }, {
    path: "/settings/staffs/new/",
    component: _4029bf67,
    pathToRegexpOptions: {"strict":true},
    name: "settings-staffs-new"
  }, {
    path: "/masters/categories/:uid/",
    component: _4562a83d,
    pathToRegexpOptions: {"strict":true},
    name: "masters-categories-uid"
  }, {
    path: "/masters/freeboxes/:uid/",
    component: _601ebeec,
    pathToRegexpOptions: {"strict":true},
    name: "masters-freeboxes-uid"
  }, {
    path: "/masters/items/:uid?/",
    component: _38f21b12,
    pathToRegexpOptions: {"strict":true},
    name: "masters-items-uid"
  }, {
    path: "/masters/options/:uid/",
    component: _6a1ad199,
    pathToRegexpOptions: {"strict":true},
    name: "masters-options-uid"
  }, {
    path: "/masters/vikings/:uid/",
    component: _15595bae,
    pathToRegexpOptions: {"strict":true},
    name: "masters-vikings-uid"
  }, {
    path: "/settings/payment_methods/:uid/",
    component: _5661966a,
    pathToRegexpOptions: {"strict":true},
    name: "settings-payment_methods-uid"
  }, {
    path: "/settings/staffs/:uid/",
    component: _2f96af4c,
    pathToRegexpOptions: {"strict":true},
    name: "settings-staffs-uid"
  }, {
    path: "/tables/:uid/",
    component: _5181ad52,
    pathToRegexpOptions: {"strict":true},
    name: "tables-uid"
  }, {
    path: "/",
    component: _2003f0e9,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
