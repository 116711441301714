import _ from 'lodash';
// import dayjs from 'dayjs';
// let now = dayjs()

const addBlankToSelect = function(arr){
  arr = _.cloneDeep(arr)
  arr.unshift({name_inner: "--- 選んでください ---", name: "--- 選んでください ---", id: null}) 
  return arr
}

const paymentStatus = function(payment){
  if(payment.status === "canceled"){
    // 赤伝票
    if(payment.group.pay_called_at === null){
      // 赤伝票修正済み
      return "canceled_fix_done"
    }else{
      // 赤伝票
      return "canceled_only"
    }
  }else if(payment.status === "fix_paied"){
    // 修正伝票
    return "fix_paied"
  }else{
    return "paied"
  }
}

const formatted_payment_details = (payment) => {
  let formatted_payment_details = []
  let pds = _.cloneDeep(payment.payment_details)
  pds.forEach(pd => {
    let selectedPD = _.find(formatted_payment_details, ["payment_method_id", pd.payment_method_id])
    if(selectedPD){
      selectedPD.price_sum += pd.price_sum
      selectedPD.otsuri_price += pd.otsuri_price
      selectedPD.azukari_price += pd.azukari_price
    }else{
      formatted_payment_details.push(pd)
    }
  })
  return formatted_payment_details
}

const sleep = async (msec) => {
  return new Promise(function(resolve) {
     setTimeout(function() {resolve()}, msec);
  })
}

const playSound = async (type = "drawer") => {
  let soundPath = "/sound/drawer.mp3"
  if(type === "chaim") soundPath = "/sound/chaim.mp3"
  if(type === "call") soundPath = "/sound/call.mp3"
  if(type === "btn") soundPath = "/sound/btn.mp3"
  const music = new Audio(soundPath)
  music.play();
}

export {
  addBlankToSelect,
  paymentStatus,
  formatted_payment_details,
  sleep,
  playSound,
}