import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import { sleep } from '~/utils/tool'

const getDefaultState = () => {
  return {
    // contact
    firstContact: false,

    // auth
    isAdmin: false,
    isHandy: false,
    authToken: null,
    shop_id: null,
    staff_id: null,

    // push
    oneSignalDeviseToken: null,
    loginDeviseTokenDone: false,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // contact
  firstContactDone(state, payload) {
    state.firstContact = true
  },
  
  // auth
  setHandy(state, payload) {
    state.isHandy = payload
  },
  setIsAdmin(state, isAdmin) {
    state.isAdmin = isAdmin
  },
  setAuthToken(state, payload) {
    state.authToken = payload.auth_token
    state.shop_id = payload.shop_id
  },
  logout(state) {
    // action cable disconnect
    // window.storeCtl.dispatch("actionCable/removeSpecifiedChannel", "ShopChannel")
    window.storeCtl.state.fireStore.fireStoreObserver()

    window.storeCtl.commit("shop/reset")
    window.storeCtl.commit("dashbord/resetAll")
    window.storeCtl.commit("master/resetAll")
    window.storeCtl.commit("floor/resetAll")
    window.storeCtl.commit("fireStore/resetAll")

    if(state.isHandy === true){
      $nuxt.$router.push(`/login/handy/`)
    }else{
      $nuxt.$router.push(`/login/`)
    }

    Object.assign(state, getDefaultState())

  },

  // push
  setOneSignalDeviseToken(state, oneSignalDeviseToken) {
    state.oneSignalDeviseToken = oneSignalDeviseToken
  },
  setLoginDeviseTokenDone(state) {
    state.loginDeviseTokenDone = true
  },
}
export const getters = {
  isLogin(state) {
    return !!state.authToken
  },
  hasRight: state => (acton_type) => {
    let rights = window.storeCtl.state.shop.settings.rights
    if(acton_type === "order") return rights.canOrder === "yes" ? true : false
    if(acton_type === "regi") return rights.canRegi === "yes" ? true : false
    if(acton_type === "akaden") return rights.canAkaden === "yes" ? true : false
    if(acton_type === "sales_report") return rights.canSalesReport === "yes" ? true : false
    if(acton_type === "table") return rights.canTable === "yes" ? true : false
    if(acton_type === "menu") return rights.canMenu === "yes" ? true : false
    if(acton_type === "settings") return rights.canSettings === "yes" ? true : false
    return false
  },
}

// 戻り地はisBeforeRequest
export const actions = {
  async login(context, payload){
    let self = this
    window.storeCtl.commit("loading/start")
    let res = await httpClient
    .post('/shp/account/login.json', {
      email: payload.email,
      password: payload.password,
      confirm_token: payload.confirm_token,
      devise_token: payload.devise_token,
      handy_token: payload.handy_token,
      devise_type: payload.devise_type,
    })
    .then(async (res) => {
      if (res.data.status === 'success') {
        if(payload.devise_type === "handy"){
          window.storeCtl.commit("auth/setHandy", true)
        }
        window.storeCtl.commit('devise/setDeviseType', res.data.data.devise_type)
        window.storeCtl.commit('devise/setDeviseToken', res.data.data.devise_token)
        window.storeCtl.commit('devise/setHandyToken', res.data.data.handy_token)
        window.storeCtl.commit('auth/setAuthToken', res.data.data)
        
        // getAllMasters
        await window.storeCtl.dispatch("auth/getAllMasters")

        //　ハンディかどうかでルート分ける
        if(window.storeCtl.state.auth.isHandy === true){
          $nuxt.$router.push('/orders/')
        }else{
          // リダイレクトURLあればそこに飛ばす
          if(window.storeCtl.state.devise.loginRedirectPath){
            let path = _.cloneDeep(window.storeCtl.state.devise.loginRedirectPath)
            window.storeCtl.commit("devise/setLoginRedirectPath", null)
            return $nuxt.$router.push(path)
          }{
            return $nuxt.$router.push('/')
          }
        }
        return true
      } else if(res.data.status === "devise_not_authorize"){
        window.storeCtl.commit("loading/stop")
        return false
      } else {
        window.storeCtl.commit("loading/stop")
        window.storeCtl.commit("alert/setError", res.data.message)
        return true
      }
    })
    .finally(() => {
      window.storeCtl.commit("loading/stop")
    })
    return res
  },

  async getAllMasters(context){
    window.storeCtl.commit("loading/start")

    // 一気にったくとエラーになりやすいので分ける
    // ここで一度マスターをすべて取得
    await Promise.all([
      window.storeCtl.dispatch('shop/getSettings'),
      window.storeCtl.dispatch('shop/getShopTrend'),
    ])
    await sleep(500)
    await Promise.all([
      window.storeCtl.dispatch('master/getItemMasters'),
    ])
    await sleep(500)
    await Promise.all([
      window.storeCtl.dispatch('master/getTables'),
      window.storeCtl.dispatch('master/getPaymentMethods'),
      window.storeCtl.dispatch('master/getStaffs'),
    ])
    await sleep(500)
    await Promise.all([
      window.storeCtl.dispatch('floor/getSeatingGroups'),
      window.storeCtl.dispatch("floor/getOrderSets", {mode: "orderd_or_cooked"}),
    ])
    await sleep(500)
    await Promise.all([
      window.storeCtl.dispatch("floor/getOrderSets", {mode: "all", init: true}),
      window.storeCtl.dispatch('floor/getPayments', {init: true}),
    ])

    window.storeCtl.commit("loading/stop")

  },

  // //// auth
  // async ResendAuthToken(context, auth_type){
  //   await httpClient
  //   .post('/front/account/resend_auth_token.json', {auth_type: auth_type})
  //   .then((res) => {
  //     if (res.data.status === 'success') {
  //       context.dispatch("getCurrentUser")
  //       window.storeCtl.commit("alert/setSuccess", "認証コードを送付しました")
  //     } else {
  //       window.storeCtl.commit("alert/setError", res.data.message)
  //     }
  //   })
  // },
  // async VerifyAuthToken(context, payload){
  //   await httpClient
  //   .post('/front/account/verify_auth_token.json', {
  //     auth_type: payload.auth_type,
  //     token: payload.token,
  //   })
  //   .then((res) => {
  //     if (res.data.status === 'success') {
  //       context.dispatch("getCurrentUser")
  //       if(payload.linkToTop){
  //         context.commit("setTwoFactorAuthAt", dayjs().format('YYYY-MM-DD HH:mm:SS'))
  //         $nuxt.$router.push(`/`)
  //       }else{
  //         window.storeCtl.commit("alert/setSuccess", "コード認証が完了しました")
  //       }
  //     } else {
  //       window.storeCtl.commit("alert/setError", res.data.message)
  //     }
  //   })
  // },

  //// push
  async updateOneSignalDeviseToken(context, oneSignalDeviseToken){
    context.commit('setOneSignalDeviseToken', oneSignalDeviseToken)
    let url, options = null
    if(context.state.authToken){
      url = '/shop/account/update_device_token.json'
      options = {onesignal_device_token: oneSignalDeviseToken}
      if(context.state.oneSignalDeviseToken && context.state.loginDeviseTokenDone) return
    }
    console.log("updateOneSignalDeviseToken")
    await httpClient
    .post(url, options)
    .then((res) => {
      if (res.data.status === 'success') {
        if(context.state.authToken){ 
          context.commit('setLoginDeviseTokenDone')
        }else{
          context.commit('setGuestDeviseTokenDone')
        }
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },
}
