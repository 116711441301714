export const state = () => ({
  isLoading: false,
})
export const getters = {
  isLoading(state) {
    return state.isLoading
  },
}
export const mutations = {
  start(state) {
    state.isLoading = true
  },
  stop(state) {
    state.isLoading = false
  }
}
