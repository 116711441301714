//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { prepareSound } from '~/utils/tool'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
    }),
  },
  methods: {
    async linkTo(path){
      if(path === '/orders/') this.$store.commit("sound/init")
      this.$router.push(path)
    },
  },
}
