import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';

// ローカルにプリント履歴がなくて５分以内のオーダーはプリント
const checkNewOrderPrintWithQue = (new_order_sets) => {
  console.log("checkNewOrderPrintWithQue")
  let base_time = dayjs().subtract(5, 'm')

  // 未プリント分
  let diff_order_sets = _.filter(new_order_sets, os => {
    return dayjs(os.created_at).isAfter(base_time)
  })

  // 履歴に内IDをリスト化、してないものは追加してユニーク化
  let printed_order_set_ids = _.cloneDeep(window.storeCtl.state.floor.printed_order_set_ids)
  let new_print_order_sets = []
  diff_order_sets.forEach(nos => {
    if(!printed_order_set_ids.includes(nos.id)) new_print_order_sets.push(nos)
    printed_order_set_ids.push(nos.id)
  })
  printed_order_set_ids = _.uniq(printed_order_set_ids)
  // 配列を100件以下にする
  let delete_size = printed_order_set_ids.length > 100 ? (printed_order_set_ids.length - 100) : 0
  if(delete_size > 0) printed_order_set_ids.splice(0, delete_size)
  window.storeCtl.commit("floor/setPrintedOrderSetIds", printed_order_set_ids)

  // フィルタたされたOSをプリント
  if(new_print_order_sets.length > 0) window.storeCtl.commit("sound/play","chaim")
  if(window.storeCtl.state.printer.print_auto_on_kitchen === true){
    console.log("new_print_order_sets", new_print_order_sets.length, new_print_order_sets)
    new_print_order_sets.forEach(os => {
      window.storeCtl.dispatch('printer/requestPrint', {
        type: "kitchen",
        order_set: os,
      })
    })
  }
}

// // こいつはキューに貯めるだけ
// const checkNewOrderPrint = (old_order_sets, new_order_sets) => {
//   console.log("checkNewOrderPrint", old_order_sets, new_order_sets)
//   let diff_order_sets = []

//   // 新しいOrderSetを抽出
//   // ※ここでプリントしていないOSを抽出
//   if(old_order_sets.length === 0){
//     // OLDなければ一旦全部DIFF
//     diff_order_sets = new_order_sets
//   }else{
//     let latest_old_order_set = _.orderBy(old_order_sets, 'created_at', 'desc')[0]
//     let old_order_sets_ids = _.map(old_order_sets, 'id');
//     new_order_sets.forEach(nos => {
//       // OLDに含まれなくてOLDの最新版よりもIDが若い
//       if(!old_order_sets_ids.includes(nos.id) && nos.id > latest_old_order_set.id){
//         diff_order_sets.push(nos)
//       }
//     })
//   }

//   // 新しいOrderSetの中で、5分以内にオーダーのみプリント
//   let base_time = dayjs().subtract(5, 'm')
//   diff_order_sets = _.filter(diff_order_sets, os => {
//     return dayjs(os.created_at).isAfter(base_time)
//   })
//   let diff_order_sets_ids = _.map(diff_order_sets, 'id');
//   console.log("diff_order_sets", diff_order_sets_ids)
//   // console.log("diff_order_sets", diff_order_sets.length, diff_order_sets)
//   // フィルタたされたOSをプリント
//   if(diff_order_sets.length > 0) window.storeCtl.commit("sound/play","chaim")
//   if(window.storeCtl.state.printer.print_auto_on_kitchen === true){
//     diff_order_sets.forEach(os => {
//       window.storeCtl.dispatch('printer/requestPrint', {
//         type: "kitchen",
//         order_set: os,
//       })
//     })
//   }
// }

const getDefaultState = () => {
  return {
    // groups
    groups: [],

    // orderd_orders
    orderd_order_get_at: null,
    orderd_order_sets: [],

    // printer
    printed_order_set_ids: [],

    // done_orders
    all_order_sets: [],
    allOrderSetPager: {
      current_page: 0,
      next_page: 1,
    },
    
    // payments
    payments: [],
    paymentPager: {
      current_page: 0,
      next_page: 1,
    },

    selectedTable: null,
    selectedGroup: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // FIXME：共通処理
  resetAll(state){
    Object.assign(state, getDefaultState())
  },

  // groups
  setSeatingGroups(state, data) {
    state.groups = data.groups
  },
  setSelectedGroup(state, group){
    state.selectedGroup = group
  },
  
  // orderd order_set
  setOrderdOrderGetAt(state, time){
    state.orderd_order_get_at = time
  },
  setOrderdOrderSet(state, order_sets){
    state.orderd_order_sets = order_sets
    for(let i = 0; i < state.orderd_order_sets.length; i++){
      let os = state.orderd_order_sets[i]
      os.is_selected = false
      os.orders = _.filter(os.orders, (o) => { return ["orderd","cooked"].includes(o.status) && ["item","viking","viking_item","gift"].includes(o.order_type)})
      for(let ii = 0; ii < os.orders.length; ii++){
        let o = os.orders[ii]
        if(o.status === 'cooked'){
          o.is_selected = true
        }else{
          o.is_selected = false
        }
        o.isLoading = false
      }
    }
  },
  replaeOrderdOrderSet(state, order_set){
    let newOrderdOrderSets = []
    state.orderd_order_sets.forEach(oos => {
      if(oos.id === order_set.id){
        newOrderdOrderSets.push(order_set)
      }else{
        newOrderdOrderSets.push(oos)
      }
    })
    state.orderd_order_sets = newOrderdOrderSets
  },

  // printed_order_set_ids
  setPrintedOrderSetIds(state, printed_order_set_ids){
    state.printed_order_set_ids = printed_order_set_ids
  },

  // done orders
  resetAllOrderSet(state){
    state.all_order_sets = []
    state.allOrderSetPager = {
      current_page: 0,
      next_page: 1,
    }
  },
  initAllOrderSets(state, data) {
    state.all_order_sets = data.order_sets
    state.allOrderSetPager = data.pages
  },
  addAllOrderSets(state, data) {
    state.all_order_sets = _.unionBy(state.all_order_sets, data.order_sets, 'id')
    state.allOrderSetPager = data.pages
  },

  // payments
  resetPayment(state){
    state.payments = []
    state.paymentPager = {
      current_page: 0,
      next_page: 1,
    }
  },
  initPayments(state, data) {
    state.payments = data.payments
    state.paymentPager = data.pages
  },
  addPayments(state, data) {
    state.payments = _.unionBy(state.payments, data.payments, 'id')
    state.paymentPager = data.pages
  },

  // table
  changeTableDetail(state, table_id) {
    state.selectedTable = _.find(window.storeCtl.state.master.tables, ["id", table_id])
    if(state.selectedTable && state.selectedTable.group){
      state.selectedGroup = state.selectedTable.group
    }else{
      state.selectedGroup = null
    }
  },
}
export const getters = {
  orderd_order_size(state) {
    let size = 0
    state.orderd_order_sets.forEach(os => {
      os.orders.forEach(o => {
        if(o.cook_at === null) size += o.count
      })
    })
    return size
  },
  cooked_order_size(state) {
    let size = 0
    state.orderd_order_sets.forEach(os => {
      os.orders.forEach(o => {
        if(o.cook_at !== null) size += o.count
      })
    })
    return size
  },
  pay_requested_group_size(state) {
    let size = 0
    state.groups.forEach(g => {
      if(g.pay_request_at !== null) size += 1
    })
    return size
  },
}
export const actions = {
  async checkin(context, {table, member_size}){
    window.storeCtl.commit("loading/start")
    let url = `/shp/floor/tables/${table.uid}/checkin.json`
    let res = await httpClient
    .put(url, {
      member_size: member_size,
    })
    .then(async (res) => {
      window.storeCtl.commit("loading/stop")
      if (res.data.status === 'success') {
        await window.storeCtl.dispatch("master/getTables")
        return true
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
        return false
      }
    })
    return res
  },  

  async getSeatingGroups(context){
    await httpClient
    .get(`/shp/floor/groups/seatings.json`)
    .then((res) => {
      if (res.data.status === 'success') {
        context.commit("setSeatingGroups", res.data.data)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },  

  async printOrderSet(context, params){
    await httpClient
      .get(`/shp/floor/order_sets/${params.order_set_uid}.json`)
      .then(async (res) => {      
        if (res.data.status === 'success') {
          checkNewOrderPrintWithQue([res.data.data.order_set])
        }else{
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
  },

  async getOrderSets(context, params){
    let url = `/shp/floor/order_sets.json`
    await httpClient
    .get(url, {
      params: params
    })
    .then(async (res) => {
      
      if (res.data.status === 'success') {
        if(params.mode === "orderd_or_cooked"){
          context.commit("setOrderdOrderSet", res.data.data.order_sets)
          if(params.doPrint){
            console.log("getOrderSets print do")
            checkNewOrderPrintWithQue(res.data.data.order_sets)
          }else{
            console.log("getOrderSets print skip")
          }
        }else{
          if(params.init && params.init === true){
            context.commit("initAllOrderSets", res.data.data)
          }else{
            context.commit("addAllOrderSets", res.data.data)
          }
        }
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
    console.log("getOrderSets done")
  },  

  async getPayments(context, params){
    let url = `/shp/floor/payments.json`
    await httpClient
    .get(url, {
      params: params
    })
    .then((res) => {
      if (res.data.status === 'success') {
        if(params.init && params.init === true){
          context.commit("initPayments", res.data.data)
        }else{
          context.commit("addPayments", res.data.data)
        }
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },  
}
