import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as originalRules from 'vee-validate/dist/rules';
import ja from 'vee-validate/dist/locale/ja.json';
import isCreditCard from 'validator/lib/isCreditCard';
import dayjs from 'dayjs';

// 全てのルールをインポート
let rule;
for (rule in originalRules) {
  extend(rule, {
    ...originalRules[rule], // eslint-disable-line
  });
}
extend('credit_card', {
  validate: (val) => isCreditCard(val),
  message: 'カード番号の形式が不正です'
});

extend('future_day', {
  validate: (value) => {
    if (dayjs() > dayjs(value)){
      return false
    }else{
      return true
    }
  },
  message: "未来日を設定してください",
});

extend('day_after_start_day', {
  params: ['target'],
  validate(value, { target }) {
    if (dayjs(value) > dayjs(target)){
      return true
    }else{
      return false
    }
  },
  message: '終了日は開始日よりも未来日にしてください'
});

// メッセージを設定
localize('ja', ja);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
