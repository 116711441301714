export const state = () => ({
  time: null,
  timeout: 4000,
  message: null,
  color: null,
})
export const getters = {
  getTime(state) {
    return state.time
  },
}
export const mutations = {
  setInfo(state, message) {
    state.time = Date.now()
    state.timeout = 4000
    state.message = message
    state.color = "secondary"
  },
  setSuccess(state, message) {
    state.time = Date.now()
    state.timeout = 4000
    state.message = message
    state.color = "success"
  },
  setError(state, message){
    state.time = Date.now()
    state.timeout = 4000
    state.message = message
    state.color = "red"
  },
  setCart(state, message) {
    state.time = Date.now()
    state.timeout = 1000
    state.message = message
    state.color = "success"
  },
}
