export const state = () => ({
  showFirstLikeModal: false,
  showFirstContactModal: false,
  showLikeShower: false,
})
export const mutations = {
  setFirstLikeModal(state, payload) {
    state.showFirstLikeModal = payload
  },
  setFirstContactModal(state, payload) {
    state.showFirstContactModal = payload
  },
  setLikeShower(state, payload) {
    state.showLikeShower = payload
  }
}
