import Vue from 'vue'
import { isDev } from '~/utils/env'

let appConfigs = {}
if (isDev()) {
  appConfigs.customerUrl = "http://localhost:3000"

} else {
  appConfigs.customerUrl = "https://cus.spde.menu"
}

// 共通
// appConfigs.cloudinaryApiKey = "239742381986822"


Vue.prototype.$appConfigs = appConfigs