const getDefaultState = () => {
  return {
    devise_type: null,
    devise_token: null,
    handy_token: null,
    loginRedirectPath: null,
    isOnlyLatest100: false,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  setDeviseType(state, devise_type) {
    state.devise_type = devise_type
  },
  setDeviseToken(state, devise_token) {
    state.devise_token = devise_token
  },
  setHandyToken(state, handy_token) {
    state.handy_token = handy_token
  },
  setLoginRedirectPath(state, path) {
    state.loginRedirectPath = path
  },  
  setIsOnlyLatest100(state, path) {
    state.isOnlyLatest100 = path
  },  
}