import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';

const getDefaultState = () => {
  return {
    categoryTabIndex: 0,
    sortedCategories: [],

    categories: [],
    items: [],
    options: [],
    vikings: [],
    freeboxes: [],
    tables: [],
    payment_methods: [],
    staffs: [],
    isOnlyActiveMenu: "no",
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  resetAll(state){
    Object.assign(state, getDefaultState())
  },

  setCategoryTabIndex(state, index){
    state.categoryTabIndex = index
  },

  // カテゴリツリーにソートされたアイテムを入れる
  formatedCategories(state){
    let newCategories = []
    state.categories.forEach(cat => {
      let lists = []
      lists = lists.concat(state.items.filter(it => {return it.category_id === cat.id}))
      lists = lists.concat(state.vikings.filter(vk => {return vk.category_id === cat.id}))
      let freeboxes = state.freeboxes.filter(fb => {return fb.category_id === cat.id})
      freeboxes.forEach(fb => {
        let selctedItems = state.items.filter(it => {return _.includes(fb.option_ids, it.id)})
        fb.items = selctedItems
      })
      lists = lists.concat(freeboxes)
      lists = _.orderBy(lists, 'sort', 'asc');
      let newCategory = _.cloneDeep(cat)
      newCategory.lists = lists
      newCategories.push(newCategory)
    })
    state.sortedCategories = newCategories
  },
  
  // マスタ
  setCategories(state, lists) {
    state.categories = _.orderBy(lists, 'sort', 'asc');
  },
  setItems(state, lists) {
    state.items = _.orderBy(lists, 'sort', 'asc');
  },
  setOptions(state, lists) {
    state.options = _.orderBy(lists, 'sort', 'asc');
  },
  setVikings(state, lists) {
    state.vikings = _.orderBy(lists, 'sort', 'asc');
  },
  setFreeboxes(state, lists) {
    state.freeboxes = _.orderBy(lists, 'sort', 'asc');
  },
  setTables(state, lists) {
    state.tables = _.orderBy(lists, 'sort', 'asc');
  },
  setPaymentMethods(state, lists) {
    state.payment_methods = _.orderBy(lists, 'sort', 'asc');
  },
  setStaffs(state, lists) {
    state.staffs = _.orderBy(lists, 'sort', 'asc');
  },

  setIsOnlyActiveMenu(state, value){
    state.isOnlyActiveMenu = value
  },

}
export const getters = {
  isOnlyActiveMenu(state){
    return state.isOnlyActiveMenu
  },
  using_table_size(state) {
    let size = 0
    state.tables.forEach(t => {
      if(t.group_id !== null) size += 1
    })
    return size
  },
  blank_table_size(state) {
    let size = 0
    state.tables.forEach(t => {
      if(t.group_id === null) size += 1
    })
    return size
  },
}
export const actions = {
  //// all
  async getItemMasters(context){
    await httpClient
    .get(`/shp/masters/categories/all.json`)
    .then((res) => {
      if (res.data.status === 'success') {
        context.commit("setCategories", res.data.data.categories)
        context.commit("setItems", res.data.data.items)
        context.commit("setVikings", res.data.data.vikings)
        context.commit("setFreeboxes", res.data.data.freeboxes)
        context.commit("setOptions", res.data.data.options)
        context.commit("formatedCategories")
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  //// category
  async getCategories(context){
    let url = `/shp/masters/categories.json`
    await httpClient
    .get(url)
    .then((res) => {
      if (res.data.status === 'success') {
        context.commit('setCategories', res.data.data.categories)
        context.commit("formatedCategories")
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  //// item
  async getItems(context){
    let url = `/shp/masters/items.json`
    await httpClient
    .get(url)
    .then((res) => {
      if (res.data.status === 'success') {
        context.commit('setItems', res.data.data.items)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  //// option
  async getOptions(context){
    let url = `/shp/masters/options.json`
    await httpClient
    .get(url)
    .then((res) => {
      if (res.data.status === 'success') {
        context.commit('setOptions', res.data.data.options)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  //// viking
  async getVikings(context){
    let url = `/shp/masters/vikings.json`
    await httpClient
    .get(url)
    .then((res) => {
      if (res.data.status === 'success') {
        context.commit("setVikings", res.data.data.vikings)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  //// freeboxes
  async getFreeboxes(context){
    let url = `/shp/masters/freeboxes.json`
    await httpClient
    .get(url)
    .then((res) => {
      if (res.data.status === 'success') {
        context.commit('setFreeboxes', res.data.data.freeboxes)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  //// tables
  async getTables(context){
    let url = `/shp/masters/tables.json`
    await httpClient
    .get(url)
    .then((res) => {
      if (res.data.status === 'success') {
        context.commit('setTables', res.data.data.tables)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  //// payment_methods
  async getPaymentMethods(context){
    let url = `/shp/masters/payment_methods.json`
    await httpClient
    .get(url)
    .then((res) => {
      if (res.data.status === 'success') {
        context.commit('setPaymentMethods', res.data.data.payment_methods)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  //// staffs
  async getStaffs(context){
    let url = `/shp/masters/staffs.json`
    await httpClient
    .get(url)
    .then((res) => {
      if (res.data.status === 'success') {
        context.commit('setStaffs', res.data.data.staffs)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },
  
}
