export const state = () => ({
  isInitDone: false,
  chaim: new Audio("/sound/chaim.mp3"),
  drawer: new Audio("/sound/drawer.mp3"),
  call: new Audio("/sound/call.mp3"),
  btn: new Audio("/sound/btn.mp3"),
})
export const getters = {
}
export const mutations = {
  init(state, force = false) {
    if(state.isInitDone && force === false) return
    state.chaim.muted = true
    state.chaim.play()
    state.chaim.pause()
    state.chaim.muted = false
    state.call.muted = true
    state.call.play()
    state.call.pause()
    state.call.muted = false
    state.isInitDone = true
  },
  play(state, type) {
    try{
      console.log("in play")
      if(type === "chaim") state.chaim.play()
      if(type === "drawer") state.drawer.play()
      if(type === "call") state.call.play()
      if(type === "btn") state.btn.play()
    }catch(e){
      state.isInitDone = false
      this.$bugsnag.notify(e)
    }
  },
  // stop(state) {
  //   state.isLoading = false
  // }
}
export const action = {
}
