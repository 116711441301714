export const state = () => ({
  showDialog: false,
  title: null,
  message: null,
  result: null,
})
export const mutations = {
  async startDialog(state, payload) {
    state.showDialog = true
    state.title = payload.title
    if(payload.message) state.message = payload.message
    state.result = null
  },
  agreeDialog(state) {
    console.log("agreeDialog")
    state.title = null
    state.message = null
    state.result = true
  },
  cancelDialog(state) {
    console.log("cancelDialog")
    state.title = null
    state.message = null
    state.result = false
  },
}
