import _ from 'lodash';
import { sleep } from '~/utils/tool'

const getDefaultState = () => {
  return {
    isShopChannleSubscribed: false,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // contact
  updateShopChannelSubscribed(state, payload) {
    state.isShopChannleSubscribed = payload
  },
}
export const actions = {
  async channelSubscribe(context, channel){
    if (window.storeCtl.state.auth.authToken === null) return
    if (channel === "ShopChannel" && context.state.isShopChannleSubscribed === true) return
    // 複数回線サブスクちゃっていたら削除
    if (channel === "ShopChannel") {
      var subscriptions = $nuxt.$cable.subscriptions['subscriptions'];
      if(subscriptions.length === 1) return
      if(subscriptions.length > 1){
        console.log("removeSpecifiedChannel")
        window.storeCtl.dispatch('actionCable/removeSpecifiedChannel', channel)
      }
    }

    $nuxt.$cable.subscriptions.create({
      channel: channel,
      auth_token: window.storeCtl.state.auth.authToken,
    }, {
      connected: () => {
        console.log(`${channel} conected`)
        if(channel === "ShopChannel") context.commit("updateShopChannelSubscribed", true)
      },
      disconnected: () => {
        console.log(`${channel} disconnected`)
      },
      received: async (data) => {
        console.log(`${channel} received`, data)

        // FIXME:ここに処理を書く
        if(data.action_type === "all"){
          await Promise.all([
            window.storeCtl.dispatch('shop/getSettings'),
            window.storeCtl.dispatch('shop/getShopTrend'),
          ])
          await sleep(500)
          await Promise.all([
            window.storeCtl.dispatch('master/getItemMasters'),
            window.storeCtl.dispatch('master/getTables'),
          ])
          await sleep(500)
          await Promise.all([
              window.storeCtl.dispatch('master/getPaymentMethods'),
            window.storeCtl.dispatch('master/getStaffs'),
          ])
          await sleep(500)
          await Promise.all([
            window.storeCtl.dispatch('floor/getSeatingGroups'),
            window.storeCtl.dispatch("floor/getOrderSets", {mode: "orderd_or_cooked"}),
          ])
          await sleep(500)
          await Promise.all([
            window.storeCtl.dispatch("floor/getOrderSets", {mode: "all", init: true}),
            window.storeCtl.dispatch('floor/getPayments', {init: true}),
          ])
        }else if(data.action_type === "shop"){
          window.storeCtl.dispatch('shop/getSettings')
          window.storeCtl.dispatch('shop/getShopTrend')
        }else if(data.action_type === "master"){
          window.storeCtl.dispatch('master/getItemMasters')
          window.storeCtl.dispatch('master/getTables')
          window.storeCtl.dispatch('master/getPaymentMethods')
          window.storeCtl.dispatch('master/getStaffs')
        }else if(data.action_type === "info"){
          window.storeCtl.dispatch('shop/getShopTrend')
        }else if(data.action_type === "table"){
          window.storeCtl.dispatch("master/getTables")
        }else if(data.action_type === "call"){
          window.storeCtl.commit("sound/play","call")
          window.storeCtl.dispatch("master/getTables")
        }else if(data.action_type === "request_payment"){
          window.storeCtl.commit("sound/play","call")
          window.storeCtl.dispatch("master/getTables")
          window.storeCtl.dispatch("floor/getSeatingGroups")
        }else if(data.action_type === "group"){
          window.storeCtl.dispatch("master/getTables")
          window.storeCtl.dispatch("floor/getSeatingGroups")
        }else if(data.action_type === "order"){
          // 内部的にここでプリント処理
          window.storeCtl.dispatch("floor/getOrderSets", {mode: "orderd_or_cooked"})
          window.storeCtl.dispatch("master/getTables")
        }else if(data.action_type === "payment"){
          window.storeCtl.dispatch('floor/getPayments')
        }
      },
    })
  },

  //// チャネルの購読をとく
  removeSpecifiedChannel(context, channel) {
    var subscriptions = $nuxt.$cable.subscriptions['subscriptions'];
    subscriptions.forEach((subscription) => {
      let identifier = subscription.identifier
      let obj = JSON.parse(identifier);
      if (channel == obj.channel) {
        $nuxt.$cable.subscriptions.remove(subscription);
        if(channel === "ShopChannel") context.commit("updateShopChannelSubscribed", false)
      }
    })
  },
}
