import dayjs from 'dayjs';
import axios from 'axios'

const getDefaultState = () => {
  return {
    lists: [],
    }
}

export const state = () => (getDefaultState())
export const mutations = {
  resetAll(state){
    Object.assign(state, getDefaultState())
  },
  setLists(state, results){
    state.lists = results
    state.lists = _.orderBy(state.lists, 'created_at', 'desc');
  },
}
export const actions = {
  //// all
  async getNews(context){
    console.log("getNews")
    let token = "07c8380647e556e1260f63bdbeec540d732f50f6b8ff1a2f75a3a49070c4f3b2"
    let created_after = dayjs().subtract(3, 'month').format("YYYY-MM-DD")
    await axios
    .get("https://jcconnecthelp.zendesk.com/api/v2/help_center/articles/search.json", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        section: 9528801249433,
        "page[size]": 100,
        created_after: created_after,
        tmp: dayjs().unix(),
      }
    })
    .then((res) => {
      context.commit("setLists", res.data.results)
    })
  },
}
