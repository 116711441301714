export const state = () => ({
  isIosApp: false,
  ios_obj: {
    app_name: null, 
    app_version: null,
    devise_name: null, 
    devise_version: null
  }
})
export const mutations = {
  set(state, payload) {
    state.isIosApp = payload.isIosApp
    state.ios_obj = payload.ios_obj
  }
}
