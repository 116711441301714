import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';

import { groups, order_sets, payments } from '~/utils/seed'

const getDefaultState = () => {
  return {
    targetDate: dayjs().format("YYYY-MM-DD"),
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  resetAll(state){
    Object.assign(state, getDefaultState())
  },

  setTargetDate(state, date) {
    state.targetDate = date
  },
  setTargetDateToday(state) {
    state.targetDate = dayjs().format("YYYY-MM-DD")
  },
  TargetDateNext(state){
    state.targetDate = dayjs(state.targetDate).add(1, "d").format("YYYY-MM-DD")
  },
  TargetDatePrev(state){
    state.targetDate = dayjs(state.targetDate).subtract(1, "d").format("YYYY-MM-DD")
  },

}
export const actions = {
}
