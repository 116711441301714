import Vue from 'vue';
import { isDev } from '~/utils/env'

export default ({store, isHMR}) => {

  if (isHMR) return

  if(store.state.userAgent.isIosApp){
    console.log("this is ios so skip payjp")
    return
  }

  if (process.client) {
    let payjp_pk = isDev() ? "pk_test_976a97266d3f3e629c42cf23" : "pk_live_a94771d768f9fcc8324f5c3c"
    // 起動時だと処理できない箇所があったので５秒後
    window.setTimeout(async () => {
      console.log("payjp do")
      Vue.prototype.$payjp = window.Payjp(payjp_pk)
    }, 1000 * 2);

  }
}