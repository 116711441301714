import dayjs from 'dayjs';
import axios from 'axios'

const getDefaultState = () => {
  return {
    regi_uid: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  resetAll(state){
    Object.assign(state, getDefaultState())
  },
  setRegiUid(state, uid){
    state.regi_uid = uid
  },
}
export const actions = {
}
