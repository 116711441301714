import _ from 'lodash';
import { sleep } from '~/utils/tool'

const getDefaultState = () => {
  return {
    fireStoreObserver: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  resetAll(state){
    Object.assign(state, getDefaultState())
  },
  // contact
  setFireStoreObserver(state, fireStoreObserver) {
    state.fireStoreObserver = fireStoreObserver
  },
}
export const actions = {
  async init(context){
    if (window.storeCtl.state.auth.authToken === null) return
    if (window.storeCtl.state.fireStore.fireStoreObserver) return

    // データの監視
    try{
      const doc = this.$fire.firestore.collection('shops').doc(window.storeCtl.state.shop.settings.uid);
      let fireStoreObserver = doc.onSnapshot(async docSnapshot => {
        let data = docSnapshot.data()
        console.log("firestore",window.storeCtl.state.shop.settings.uid)
        console.log(`Received doc snapshot:`, data)

        // FIXME:ここに処理を書く
        if(data.action_type === "all"){
          await Promise.all([
            window.storeCtl.dispatch('shop/getSettings'),
            window.storeCtl.dispatch('shop/getShopTrend'),
          ])
          await sleep(500)
          await Promise.all([
            window.storeCtl.dispatch('master/getItemMasters'),
            window.storeCtl.dispatch('master/getTables'),
          ])
          await sleep(500)
          await Promise.all([
              window.storeCtl.dispatch('master/getPaymentMethods'),
            window.storeCtl.dispatch('master/getStaffs'),
          ])
          await sleep(500)
          await Promise.all([
            window.storeCtl.dispatch('floor/getSeatingGroups'),
            window.storeCtl.dispatch("floor/getOrderSets", {mode: "orderd_or_cooked"}),
          ])
          await sleep(500)
          await Promise.all([
            window.storeCtl.dispatch("floor/getOrderSets", {mode: "all", init: true}),
            window.storeCtl.dispatch('floor/getPayments', {init: true}),
          ])
        }else if(data.action_type === "shop"){
          window.storeCtl.dispatch('shop/getSettings')
          window.storeCtl.dispatch('shop/getShopTrend')
        }else if(data.action_type === "master"){
          window.storeCtl.dispatch('master/getItemMasters')
          window.storeCtl.dispatch('master/getTables')
          window.storeCtl.dispatch('master/getPaymentMethods')
          window.storeCtl.dispatch('master/getStaffs')
        }else if(data.action_type === "info"){
          window.storeCtl.dispatch('shop/getShopTrend')
        }else if(data.action_type === "table"){
          window.storeCtl.dispatch("master/getTables")
        }else if(data.action_type === "call"){
          window.storeCtl.commit("sound/play","call")
          window.storeCtl.dispatch("master/getTables")
        }else if(data.action_type === "request_payment"){
          window.storeCtl.commit("sound/play","call")
          window.storeCtl.dispatch("master/getTables")
          window.storeCtl.dispatch("floor/getSeatingGroups")
        }else if(data.action_type === "group"){
          window.storeCtl.dispatch("master/getTables")
          window.storeCtl.dispatch("floor/getSeatingGroups")
        }else if(data.action_type === "order"){
          // 内部的にここでプリント処理
          if(data.order_set_id){
            window.storeCtl.dispatch("floor/printOrderSet", {order_set_id: data.order_set_id, order_set_uid: data.order_set_uid})
          }
          window.storeCtl.dispatch("floor/getOrderSets", {mode: "orderd_or_cooked"})
          window.storeCtl.dispatch("master/getTables")
        }else if(data.action_type === "payment"){
          window.storeCtl.dispatch('floor/getPayments')
        }
      }, err => {
        console.log(`Encountered error: ${err}`);
      });

      context.commit("setFireStoreObserver", fireStoreObserver)    
    
    }catch(e){
      const doc = this.$fire.firestore.collection('shops').doc(window.storeCtl.state.shop.settings.uid);
      this.$fire.firestore.collection("shops").doc(window.storeCtl.state.shop.settings.uid).set({
          action_type: "all",
          channel: "shop_channel",
        }, { merge: true }).then(response => {
          // 更新成功時の処理
          console.log("firestore init setup", response)
        }).catch(error => {
          // 更新失敗時の処理
        })
    }
  },

  //// チャネルの購読をとく
  destroy(context) {
    if (window.storeCtl.state.fireStore.fireStoreObserver === null) return
    window.storeCtl.state.fireStore.fireStoreObserver()
    context.commit("resetAll")  
  },
}

// firestore https://firebase.google.com/docs/firestore/query-data/listen?hl=ja


// データ取得
// let obj = this.$fire.firestore.collection("shops").doc("dFmvYLF3xZzfW30OhhZA")
// obj.get() // 取得
//   .then(response => {
//     console.log("obj response", response.data())
//     // 取得成功時の処理
//   }).catch(error => {
//     // 取得失敗時の処理
//   })

// // データ更新
// this.$fire.firestore.collection("shops").doc("dFmvYLF3xZzfW30OhhZA").set({
//     name: "harusou2"
//     // ドキュメントオブジェクト
//   }, { merge: true }).then(response => {
//     // 更新成功時の処理
//     console.log("obj set", response)
//   }).catch(error => {
//     // 更新失敗時の処理
//   })

//　データ追加
// this.$fire.firestore.collection("shops").add({
//     // ドキュメントオブジェクト
//     name: "harusou!!!",
//     id: 12345,
//   }).then(response => {
//     // 登録成功時の処理
//     console.log("obj add", response)
//   }).catch(error => {
//     // 登録失敗時の処理
//   })

// // データの監視
// const doc = this.$fire.firestore.collection('shops').doc('dFmvYLF3xZzfW30OhhZA');
// self.fireStoreObserver = doc.onSnapshot(docSnapshot => {
//   let action_data = docSnapshot.data()
//   console.log(`Received doc snapshot:`, action_data);
//   // ...
// }, err => {
//   console.log(`Encountered error: ${err}`);
// });