import { mapState } from 'vuex'
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import Compressor from 'compressorjs'
import { isDev } from '~/utils/env'

export default {
  computed: {
    isDev(){
      return isDev()
    },
    ...mapState({
      userAgent: state => state.userAgent,
      auth: state => state.auth,
      shop: state => state.shop,
      shopSettings: state => state.shop.settings,
      shopTrend: state => state.shop.shopTrend,
      currentStaff: state => state.shop.currentStaff,
      dashbord: state => state.dashbord,
      sortedCategories: state => state.master.sortedCategories,
      categories: state => state.master.categories,
      items: state => state.master.items,
      options: state => state.master.options,
      vikings: state => state.master.vikings,
      freeboxes: state => state.master.freeboxes,
      tables: state => state.master.tables,
      payment_methods: state => state.master.payment_methods,
      staffs: state => state.master.staffs,
      groups: state => state.floor.groups,
      orderd_order_sets: state => state.floor.orderd_order_sets,
      all_order_sets: state => state.floor.all_order_sets,
      allOrderSetPager: state => state.floor.allOrderSetPager,
      payments: state => state.floor.payments,
      paymentPager: state => state.floor.paymentPager,
      news: state => state.news,
      printer: state => state.printer,

      // table
      selectedTable: state => state.floor.selectedTable,
      selectedGroup: state => state.floor.selectedGroup,
      targetDate: state => state.dashbord.targetDate,
    }),
    includeTax(){
      return this.shopSettings.is_include_tax ? true : false
    },
    taxModeText(){
      return this.shopSettings.is_include_tax ? "税込" : "税抜"
    },
  },
  methods: {
    linktTo(path){
      this.$router.push(pash)
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

    //// マスタ周り
    getTable(table_id){
      return _.find(this.tables, ["id", table_id])
    },
    getGroup(group_id){
      return _.find(this.groups, ["id", group_id])
    },
    getOptions(option_ids){
      return _.filter(this.options, (o) => { return option_ids.includes(o.id) })
    },

    //// status
    shopStatusColor(business_status){
      if(business_status === "open") return "#28a745"
      if(business_status === "closed") return "#f20303"
      if(business_status === "point_closed") return "#f28c03"
    },
    paymentStatusColor(status){
      if(status === "paied") return "#f28c03"
      if(status === "canceled") return "#f20303"
      if(status === "fix_paied") return "#f28c03"
    },
    paymentStatusText(status){
      if(status === "paied") return "支払い済み"
      if(status === "canceled") return "赤伝票"
      if(status === "fix_paied") return "修正会計済み"
    },
    itemTaxTypeText(tax_rate){
      if(tax_rate === 0) return "非税"
      if(tax_rate === 8) return "軽減"
      if(tax_rate === 10) return "標準"
    },

    //// 画像周り
    // comparess
    async comparessImage(maxWidth, maxHeight, file){
      const newFile = await new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 1,
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          success: resolve,
          error: reject,
        });
      });
      return newFile
    },


  }
}
