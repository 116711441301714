export const CommonCalucurator = () => import('../../components/common/Calucurator.vue' /* webpackChunkName: "components/common-calucurator" */).then(c => wrapFunctional(c.default || c))
export const CommonCard = () => import('../../components/common/Card.vue' /* webpackChunkName: "components/common-card" */).then(c => wrapFunctional(c.default || c))
export const CommonChackPasscode = () => import('../../components/common/ChackPasscode.vue' /* webpackChunkName: "components/common-chack-passcode" */).then(c => wrapFunctional(c.default || c))
export const CommonDatePicker = () => import('../../components/common/DatePicker.vue' /* webpackChunkName: "components/common-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonFooterMenu = () => import('../../components/common/FooterMenu.vue' /* webpackChunkName: "components/common-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonImageFileInput = () => import('../../components/common/ImageFileInput.vue' /* webpackChunkName: "components/common-image-file-input" */).then(c => wrapFunctional(c.default || c))
export const CommonImageThumbnail = () => import('../../components/common/ImageThumbnail.vue' /* webpackChunkName: "components/common-image-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const CommonLoginToAdmin = () => import('../../components/common/LoginToAdmin.vue' /* webpackChunkName: "components/common-login-to-admin" */).then(c => wrapFunctional(c.default || c))
export const CommonMenuItem = () => import('../../components/common/MenuItem.vue' /* webpackChunkName: "components/common-menu-item" */).then(c => wrapFunctional(c.default || c))
export const CommonModalItemSelect = () => import('../../components/common/ModalItemSelect.vue' /* webpackChunkName: "components/common-modal-item-select" */).then(c => wrapFunctional(c.default || c))
export const CommonModalOptionSelect = () => import('../../components/common/ModalOptionSelect.vue' /* webpackChunkName: "components/common-modal-option-select" */).then(c => wrapFunctional(c.default || c))
export const CommonNumberInput = () => import('../../components/common/NumberInput.vue' /* webpackChunkName: "components/common-number-input" */).then(c => wrapFunctional(c.default || c))
export const CommonOrderClickMenu = () => import('../../components/common/OrderClickMenu.vue' /* webpackChunkName: "components/common-order-click-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonOrderPart = () => import('../../components/common/OrderPart.vue' /* webpackChunkName: "components/common-order-part" */).then(c => wrapFunctional(c.default || c))
export const CommonPageHeader = () => import('../../components/common/PageHeader.vue' /* webpackChunkName: "components/common-page-header" */).then(c => wrapFunctional(c.default || c))
export const CommonPaymentFixStatus = () => import('../../components/common/PaymentFixStatus.vue' /* webpackChunkName: "components/common-payment-fix-status" */).then(c => wrapFunctional(c.default || c))
export const CommonPlanPart = () => import('../../components/common/PlanPart.vue' /* webpackChunkName: "components/common-plan-part" */).then(c => wrapFunctional(c.default || c))
export const CommonReceipt = () => import('../../components/common/Receipt.vue' /* webpackChunkName: "components/common-receipt" */).then(c => wrapFunctional(c.default || c))
export const CommonReceiptDone = () => import('../../components/common/ReceiptDone.vue' /* webpackChunkName: "components/common-receipt-done" */).then(c => wrapFunctional(c.default || c))
export const CommonSelectItems = () => import('../../components/common/SelectItems.vue' /* webpackChunkName: "components/common-select-items" */).then(c => wrapFunctional(c.default || c))
export const CommonStaffPicker = () => import('../../components/common/StaffPicker.vue' /* webpackChunkName: "components/common-staff-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonTablePart = () => import('../../components/common/TablePart.vue' /* webpackChunkName: "components/common-table-part" */).then(c => wrapFunctional(c.default || c))
export const CommonUserIcon = () => import('../../components/common/UserIcon.vue' /* webpackChunkName: "components/common-user-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonKaikei = () => import('../../components/common/kaikei.vue' /* webpackChunkName: "components/common-kaikei" */).then(c => wrapFunctional(c.default || c))
export const CommonKaikeiDone = () => import('../../components/common/kaikeiDone.vue' /* webpackChunkName: "components/common-kaikei-done" */).then(c => wrapFunctional(c.default || c))
export const OrderItemCountChanger = () => import('../../components/order/ItemCountChanger.vue' /* webpackChunkName: "components/order-item-count-changer" */).then(c => wrapFunctional(c.default || c))
export const OrderItemDetailModal = () => import('../../components/order/ItemDetailModal.vue' /* webpackChunkName: "components/order-item-detail-modal" */).then(c => wrapFunctional(c.default || c))
export const OrderItemName = () => import('../../components/order/ItemName.vue' /* webpackChunkName: "components/order-item-name" */).then(c => wrapFunctional(c.default || c))
export const OrderItemPrice = () => import('../../components/order/ItemPrice.vue' /* webpackChunkName: "components/order-item-price" */).then(c => wrapFunctional(c.default || c))
export const OrderOptions = () => import('../../components/order/OrderOptions.vue' /* webpackChunkName: "components/order-options" */).then(c => wrapFunctional(c.default || c))
export const SalesDataChartSales = () => import('../../components/sales_data/ChartSales.vue' /* webpackChunkName: "components/sales-data-chart-sales" */).then(c => wrapFunctional(c.default || c))
export const SalesDataChartShare = () => import('../../components/sales_data/ChartShare.vue' /* webpackChunkName: "components/sales-data-chart-share" */).then(c => wrapFunctional(c.default || c))
export const UtilAlert = () => import('../../components/util/Alert.vue' /* webpackChunkName: "components/util-alert" */).then(c => wrapFunctional(c.default || c))
export const UtilConfirmDialog = () => import('../../components/util/ConfirmDialog.vue' /* webpackChunkName: "components/util-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const UtilLoading = () => import('../../components/util/Loading.vue' /* webpackChunkName: "components/util-loading" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
