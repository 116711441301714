import dayjs from 'dayjs';
let now = dayjs()

// groups
let groups = [
  {uid: 1, id: 1, table_id: 1, created_at: now.format("YYYY-MM-DD HH:mm")},
  {uid: 2, id: 2, table_id: 2, created_at: now.format("YYYY-MM-DD HH:mm")},
  {uid: 3, id: 3, table_id: 3, created_at: now.format("YYYY-MM-DD HH:mm")},
  {uid: 4, id: 4, table_id: 4, created_at: now.format("YYYY-MM-DD HH:mm")},
  {uid: 5, id: 5, table_id: 5, created_at: now.format("YYYY-MM-DD HH:mm")},
]

// payments
let payments = [
  {name: "テーブル１", status_text: "決済完了", status: "paied", uid: 1, id: 1, table_id: 1, group_id: 1, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル2", status_text: "赤伝票", status: "canceled", uid: 2, id: 2, table_id: 2, group_id: 2, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル3", status_text: "決済完了", status: "paied", uid: 3, id: 3, table_id: 3, group_id: 3, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル4", status_text: "決済完了", status: "paied", uid: 4, id: 4, table_id: 4, group_id: 4, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル5", status_text: "決済完了", status: "paied", uid: 5, id: 5, table_id: 5, group_id: 5, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル１", status_text: "決済完了", status: "paied", uid: 1, id: 1, table_id: 1, group_id: 1, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル2", status_text: "赤伝票", status: "canceled", uid: 2, id: 2, table_id: 2, group_id: 2, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル3", status_text: "決済完了", status: "paied", uid: 3, id: 3, table_id: 3, group_id: 3, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル4", status_text: "決済完了", status: "paied", uid: 4, id: 4, table_id: 4, group_id: 4, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル5", status_text: "決済完了", status: "paied", uid: 5, id: 5, table_id: 5, group_id: 5, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル１", status_text: "決済完了", status: "paied", uid: 1, id: 1, table_id: 1, group_id: 1, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル2", status_text: "赤伝票", status: "canceled", uid: 2, id: 2, table_id: 2, group_id: 2, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル3", status_text: "決済完了", status: "paied", uid: 3, id: 3, table_id: 3, group_id: 3, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル4", status_text: "決済完了", status: "paied", uid: 4, id: 4, table_id: 4, group_id: 4, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
  {name: "テーブル5", status_text: "決済完了", status: "paied", uid: 5, id: 5, table_id: 5, group_id: 5, price: 1000, pay_at: now.format("YYYY-MM-DD HH:mm")},
]

// order_sets
let order_sets = []
function make_order(i){
  let order_set_1 = {
    uid: i * 2, time: now.add(i * 5 + 2, 'm').format("HH:mm"), 
    table_id: 1, group_id: 1, isSelected: false,
    orders: [
      {payment_id: 1, isSelected: false, name: "ビール", count: 2, selected: false, price: 200, options: []},
      {payment_id: 1, isSelected: false, name: "オレンジジュース", count: 1, selected: false, price: 100, options: []},
    ]
  }
  let order_set_2 = {
    uid: i * 2 + 1, time: now.add(i * 5, 'm').format("HH:mm"), 
    table_id: 3, group_id: 2, isSelected: false,
    orders: [
      {payment_id: 2, isSelected: false, name: "塩ラーメン", count: 2, selected: false, price: 200, options: [
        {name: "メンマ", price: 100, count: 2},
        {name: "わかめ", price: 100, count: 1},
        {name: "大盛り", price: 0, count: 1},
      ]},
      {payment_id: 2, isSelected: false, name: "味噌ラーメン", count: 2, selected: false, price: 200, options: [
        {name: "メンマ", price: 100, count: 2},
        {name: "わかめ", price: 100, count: 1},
        {name: "大盛り", price: 0, count: 1},
      ]},
      {payment_id: 3, isSelected: false, name: "塩ラーメン", count: 2, selected: false, price: 200, options: [
        {name: "メンマ", price: 100, count: 2},
        {name: "わかめ", price: 100, count: 1},
        {name: "大盛り", price: 0, count: 1},
      ]},
      {payment_id: 3, isSelected: false, name: "味噌ラーメン", count: 2, selected: false, price: 200, options: [
        {name: "メンマ", price: 100, count: 2},
        {name: "わかめ", price: 100, count: 1},
        {name: "大盛り", price: 0, count: 1},
      ]},
    ]
  }

  order_sets.push(order_set_1)
  order_sets.push(order_set_2)
}
[1,2,3,4,5,6,7,8,9,10].forEach(i => {
  make_order(i)
})

let shopTrendMonthlys = [
  {month: "2022/1"},
  {month: "2022/2"},
  {month: "2022/3"},
  {month: "2022/4"},
  {month: "2022/5"},
  {month: "2022/6"},
  {month: "2022/7"},
  {month: "2022/8"},
  {month: "2022/9"},
  {month: "2022/10"},
  {month: "2022/11"},
  {month: "2022/12"},
]
let shopTrendDayilys = []


export {
  groups, order_sets, payments,
  shopTrendMonthlys, shopTrendDayilys,
}