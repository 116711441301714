import createApp from './app.js'

import firestoreService from './service.firestore.js'

const appConfig = {"apiKey":"AIzaSyAh8ZrzwS25U04DHxhkpra4kVMPd5cy6gw","authDomain":"spdm-shop-prd.firebaseapp.com","projectId":"spdm-shop-prd","storageBucket":"spdm-shop-prd.appspot.com","messagingSenderId":"1083744139936","appId":"1:1083744139936:web:960bd44e2f0a78915ce7c5"}

export default async (ctx, inject) => {
  const { firebase, session } = await createApp(appConfig, ctx)

  let servicePromises = []

  if (process.server) {
    servicePromises = [
      firestoreService(session, firebase, ctx, inject),

    ]
  }

  if (process.client) {
    servicePromises = [
      firestoreService(session, firebase, ctx, inject),

    ]
  }

  const [
    firestore
  ] = await Promise.all(servicePromises)

  const fire = {
    firestore: firestore
  }

    inject('fireModule', firebase)
    ctx.$fireModule = firebase

  inject('fire', fire)
  ctx.$fire = fire
}

function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  if (typeof window !== "undefined" && window.$nuxt) {
  // If clause makes sure it's only run when ready() is called in a component, not in a plugin.
    window.$nuxt.$options[injectKey] = value
  }
}